<template>
    <info-form :is-edit="false" />
</template>
<script>
import infoForm from './components/form'
export default {
    name:'AddInformation',
    components:{
        infoForm
    }
}
</script>